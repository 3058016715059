import { ProductReference, ProductCoverReference } from '@aventus/platform';

export const appMap: any = {
  productOverview: {
    goTo: (
      productReference: ProductReference,
      productCoverReference: ProductCoverReference
    ) => `/product/${productReference}/${productCoverReference}`,
    pathConfiguration:
      '/product/:productReference/:productCoverReference/:productID?'
  },
  intelligentQuote: {
    goTo: (
      quoteActionOrId: string,
      productReference?: string,
      productCoverReference?: string,
      quoteId?: string,
      policyId?: string,
      questionSetReferenceId?: string,
      token?: string
    ) => {
      const params = Object.assign(
        {}, // Assign will skip over null values
        productReference && { productReference },
        productCoverReference && { productCoverReference },
        quoteId && { quoteId },
        policyId && { policyId },
        questionSetReferenceId && { questionSetReferenceId }
      );
      const queryParams = Object.keys(params)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
      const queryParamsString = queryParams.length > 0 ? '?' + queryParams : '';
      const tokenParam = queryParamsString === '' ? `?token=${token}` : `&token=${token}`
      return `/quote/${quoteActionOrId}${queryParamsString}${token ? tokenParam : ''}`;
    },
    pathConfiguration: '/quote/:quoteActionOrId'
  }
};
