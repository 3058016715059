import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { MvmtIntelligentQuoteAdjustOverview } from '@aventus/mvmt-quote';
import { useGetPolicy } from '@aventus/symphony-client/hooks';
import { useGetProductConfiguration } from '@aventus/configuration-client-context';

export const IntelligentQuoteAdjustOverview: React.FunctionComponent<
  IIntelligentQuoteAdjustOverview
> = props => {
  const { policy } = useGetPolicy(props.match.params.policyId);
  const [productReference, setProductReference] = React.useState<string>('');
  const [productCoverReference, setProductCoverReference] =
    React.useState<string>('');

  const { productConfiguration } = useGetProductConfiguration(
    productReference,
    productCoverReference
  );

  React.useEffect(() => {
    if (policy) {
      setProductReference(policy.productReferenceID);
      setProductCoverReference(policy.coverType);
    }
  }, [policy]);

  if (policy && productConfiguration) {
    return (
      <MvmtIntelligentQuoteAdjustOverview
        policyId={policy.id}
        productConfiguration={productConfiguration}
        goHome={props.goHome}
        goToOrigin={props.goToOrigin}
        goToIntelligentQuoteAskForAdjust={
          props.goToIntelligentQuoteAskForAdjust
        }
      />
    );
  }

  return null;
};

interface IIntelligentQuoteAdjustOverview
  extends RouteComponentProps<{
    policyId: string;
  }> {
  goToIntelligentQuoteAskForAdjust: (policyId: string) => void;
  goHome: () => void;
  goToOrigin: () => void;
}
