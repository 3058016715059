import * as React from 'react';
import {
  useGetQuote,
  useGetQuoteProduct
} from '@aventus/symphony-client/hooks';
import { useGetProductConfiguration } from '@aventus/configuration-client-context';
import { RouteComponentProps } from 'react-router-dom';
import {
  BladeMarkdown,
  BladeButton,
  BladeModal,
  BladeViewIsLoading,
  BladeView,
  BladeSurface
} from '@aventus/blade';
import {
  PriceDisplay,
  usePaymentPlan,
  PaymentPlanSelector
} from '@aventus/mvmt-pricing';
import { DetailsOfCover } from '@aventus/mvmt-product';
import { useDocumentTitle } from '@aventus/pockethooks';
import { useTrack, useTrackView } from '@aventus/application-tracking';
import css from './index.css';
import { queryParamGet } from '@aventus/pocketknife/query-param-get';

export const VriSummary: React.FunctionComponent<IVriSummary> = props => {
  useTrackView('VriSummary');
  useDocumentTitle(props.documentTitle);

  const { track } = useTrack();

  React.useEffect(() => {
    track({
      event: 'aventus.vri.start',
      quoteId: props.match.params.quoteId,
    });
  }, []);

  // If the user was re-directed by one of our partners, then we'd expect
  const utmSource = queryParamGet(props.location.search || '', 'utm_source') || queryParamGet(props.location.search || '', 'click_source');
  const utmMedium = queryParamGet(props.location.search || '', 'utm_medium') || queryParamGet(props.location.search || '', 'click_medium');
  const utmCampaign = queryParamGet(props.location.search || '', 'utm_campaign') || queryParamGet(props.location.search || '', 'click_campaign');

  const { quoteBundle, isGettingQuoteBundle } = useGetQuote(
    props.match.params.quoteId,
    false,
    utmSource,
    utmMedium,
    utmCampaign
  );

  const paymentPlan = usePaymentPlan(quoteBundle?.requestedQuotePricing);

  const { productConfiguration } = useGetProductConfiguration(
    quoteBundle?.requestedQuote.productReferenceID,
    quoteBundle?.requestedQuote.coverType
  );

  const { quoteProduct, isGettingQuoteProduct } = useGetQuoteProduct(
    props.match.params.quoteId
  );

  const [smallPrintDetails, setSmallPrintDetails] = React.useState<
    string | undefined
  >(undefined);

  if (isGettingQuoteBundle || isGettingQuoteProduct) {
    return <BladeViewIsLoading variant={'s'} />;
  }

  if (quoteBundle && quoteProduct && productConfiguration && paymentPlan) {
    return (
      <div className={css.scaffolding}>
        <PriceDisplay
          plan={paymentPlan.selectedPricingPlan}
          productConfiguration={productConfiguration}
          useStickyMode={true}
          quoteType={quoteBundle.requestedQuote.quoteType}
        />

        <BladeView variant={'s'} className={css.vriSummary}>
          {/* TODO: do the payment plan selector */}

          <PaymentPlanSelector
            label={productConfiguration.pricing?.paymentPlanToggleLabel}
            description={productConfiguration.pricing?.paymentPlanDescription}
            plans={quoteBundle.requestedQuotePricing}
            plansConfiguration={productConfiguration.pricing.paymentPlans}
            selectedPaymentPlan={paymentPlan.selectedPricingPlan}
            setSelectedPricingPlan={paymentPlan.setSelectedPricingPlan}
          />

          <BladeSurface shouldRespectViewWidth={true}>
            <div className={css.vriSummary_description}>
              <BladeMarkdown
                markdown={productConfiguration.quote.vri?.description || 'VRI'}
              />
            </div>

            <div className={css.vriSummary_callToAction}>
              <BladeButton
                onClick={() =>
                  props.goToQuoteStart(quoteBundle.requestedQuote.id)
                }
              >
                {productConfiguration.quote.vri?.callToAction ||
                  'Continue quote'}
              </BladeButton>
            </div>

            <BladeSurface isAside={true}>
              <div className={css.vriSummary_smallPrint}>
                <BladeMarkdown
                  action={() =>
                    setSmallPrintDetails(
                      productConfiguration.product.smallPrintDetails
                    )
                  }
                  markdown={productConfiguration.product.smallPrint}
                />
              </div>
            </BladeSurface>
          </BladeSurface>

          <DetailsOfCover
            product={quoteProduct}
            coverConfiguration={productConfiguration.product.cover}
            shouldRespectViewWidth={true}
          />

          {smallPrintDetails && (
            <BladeModal
              withFrame={true}
              close={() => setSmallPrintDetails(undefined)}
            >
              <BladeMarkdown
                size={'base'}
                markdown={productConfiguration.product.smallPrintDetails}
              />
            </BladeModal>
          )}
        </BladeView>
      </div>
    );
  }

  return null;
};

export interface IVriSummary
  extends RouteComponentProps<{
    quoteId: string;
  }> {
  goToQuoteStart: (quoteId: string) => void;
  documentTitle?: string;
}
