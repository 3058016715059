import * as React from 'react';
import {
  useGetProductConfiguration,
  useGetOrganisationConfiguration,
  useGetProductInterfaceConfiguration,
  useGetOraclesConfiguration
} from '@aventus/configuration-client-context';
import {
  useGetPolicy,
  useGetOrganisationSettings
} from '@aventus/symphony-client/hooks';
import { IIntelligentQuote } from '../index';
import { MvmtIntelligentQuoteAsk } from '@aventus/mvmt-quote';
import { queryParamGet } from '@aventus/pocketknife/query-param-get';
import { useAskForAdjust } from './use-ask-for-adjust';
import { useSessionStorage } from '@aventus/pockethooks';
import { ServerError } from '@aventus/errors';

export const AskForAdjust: React.FunctionComponent<IAskForAdjust> = props => {
  // With adjustments, we obviously are only dealing with policies.
  // So the only hook we've got is the policy id:
  // ...?policyId=X

  const policyId: string = queryParamGet(props.location.search, 'policyId');

  const { policy } = useGetPolicy(policyId);

  // Given these two references, we can pick out the specific configuration
  // for this insurance product, as well as the organisation configuration.

  const { organisationConfiguration } = useGetOrganisationConfiguration();
  const { productConfiguration } = useGetProductConfiguration(
    policy?.productReferenceID.toLowerCase(),
    policy?.coverType.toLowerCase()
  );
  const { productInterfaceConfiguration } = useGetProductInterfaceConfiguration(
    policy?.productReferenceID.toLowerCase(),
    policy?.coverType.toLowerCase()
  );
  const { oraclesConfiguration } = useGetOraclesConfiguration();

  // If we're loading an existing quote, because all we've got is the
  // quote id, then we need to fetch the quote object and pick out the product
  // and product cover reference to be able to load the correct configuration
  // file for that product.

  // If the user was re-directed by one of our partners, then we'd expect
  // that partner ID to be in our query params or localstorage under key partnerId. If it is, then we need to
  // pass it on to IQ Start so that we can attribute the conversion to them.

  const { storageValue } = useSessionStorage('partnerId');

  const partnerId: string =
    queryParamGet(props.location.search, 'partnerId') || storageValue;

  const questionSetReferenceId: string =
    queryParamGet(props.location.search, 'questionSetReferenceId') ||
    storageValue;

  // As mentioned above, we can initialise an adjustment
  // only with the original policy's ID.

  const iq = useAskForAdjust(policyId, partnerId, questionSetReferenceId);

  // Lastly we need to fetch the currency settings for this particular
  // organisation.

  const { organisationSettings } = useGetOrganisationSettings();

  React.useEffect(() => {
    props.setRateError(iq.rateError);
  }, [iq.rateError]);

  // Once we've got the product configuration,
  // we can assume we've done all the preparing we need to,
  // so we can go ahead and load the quote form.
  // Note that we don't need to wait for any of the IQ async
  // actions or initialisation. This is because the component
  // knows how to handle the IQ object in any of it's possible
  // states.

  if (
    productConfiguration &&
    organisationConfiguration &&
    productInterfaceConfiguration &&
    organisationSettings &&
    oraclesConfiguration
  ) {
    return (
      <MvmtIntelligentQuoteAsk
        documentTitle={`Insurance quote | ` + organisationConfiguration?.name}
        iq={iq}
        goHome={props.goHome}
        currencyCode={organisationSettings.currencyCode}
        currencySymbol={organisationSettings.currencySymbol}
        countryCode={organisationSettings.defaultCountryCode}
        dateFormat={organisationSettings.dateFormat}
        productConfiguration={productConfiguration}
        productInterfaceConfiguration={productInterfaceConfiguration}
        organisationConfiguration={organisationConfiguration}
        oraclesConfiguration={oraclesConfiguration}
        getHomeRoute={props.getHomeRoute}
        getSummaryRoute={props.getSummaryRoute}
        skipConfirmationPage={false}
        quoteType="MTA"
      />
    );
  }

  return null;
};

interface IAskForAdjust extends IIntelligentQuote {
  setRateError: React.Dispatch<React.SetStateAction<ServerError | undefined>>;
}
