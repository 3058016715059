import * as React from 'react';
import {
  useIntelligentQuoting,
  UseIntelligentQuoting
} from '@aventus/mvmt-quote';
import {
  useStartIntelligentQuoteForAdjust,
  useNextIntelligentQuote,
  useNextQuoteIntelligentQuote
} from '@aventus/symphony-client/hooks';
import { MTAError, ServerError } from '@aventus/errors';

export function useAskForAdjust(
  policyId?: string,
  partnerId?: string,
  questionSetReferenceID?: string
): UseAskForAdjust {
  const intelligentQuoting = useIntelligentQuoting();

  const {
    intelligentQuoteRequest,
    isStartingIntelligentQuoteForAdjust
  } = useStartIntelligentQuoteForAdjust(policyId, partnerId, questionSetReferenceID);

  React.useEffect(() => {
    if (intelligentQuoteRequest) {
      intelligentQuoting.handleStart(intelligentQuoteRequest, "Adjust");
    }
  }, [intelligentQuoteRequest]);

  const {
    intelligentQuoteResponse,
    isGettingIntelligentQuoteResponse
  } = useNextIntelligentQuote(
    intelligentQuoting.risk,
    intelligentQuoting.riskState,
    intelligentQuoting.quoteRateableState,
    intelligentQuoting.riskHash,
    intelligentQuoting.customizationNext
  );

  React.useEffect(() => {
    if (intelligentQuoteResponse) {
      intelligentQuoting.handleNext(intelligentQuoteResponse);
    }
  }, [intelligentQuoteResponse]);

  const {
    intelligentQuoteBundle,
    isGettingQuoteBundle,
    rateError,
    mtaError
  } = useNextQuoteIntelligentQuote(
    intelligentQuoting.risk,
    intelligentQuoting.riskState,
    intelligentQuoting.confirm,
    'adjust',
    intelligentQuoting.quoteState,
    intelligentQuoting.quoteRateableState,
    intelligentQuoting.riskHash,
    intelligentQuoting.previousPage
  );

  React.useEffect(() => {
    if (intelligentQuoteBundle) {
      intelligentQuoting.handleNextQuote(intelligentQuoteBundle);
    }
  }, [intelligentQuoteBundle]);

  return {
    ...intelligentQuoting,
    isWorking:
      isStartingIntelligentQuoteForAdjust ||
      isGettingIntelligentQuoteResponse ||
      isGettingQuoteBundle,
    rateError,
    mtaError
  };
}

export interface UseAskForAdjust extends UseIntelligentQuoting {
  isWorking: boolean;
  rateError: ServerError;
  mtaError: MTAError;
}
