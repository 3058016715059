import * as React from 'react';
import { useSymphonyClient } from '@aventus/symphony-client/hooks/context';
import { useError } from '@aventus/pockethooks/use-error';
import { QuoteBundle } from '@aventus/platform';
import useDispatchEvent from '@aventus/pockethooks/use-dispatch-event';

export const useGetQuote: UseGetQuote = function (
  quoteId,
  allowQuoteReRate = false,
  utmSource = undefined,
  utmMedium = undefined,
  utmCampaign = undefined
) {

  const symphony = useSymphonyClient();
  const { throwError } = useError();

  const [quoteBundle, setQuoteBundle] = React.useState<QuoteBundle | undefined>(undefined);
  const [isGettingQuoteBundle, setIsGettingQuoteBundle] = React.useState<boolean>(false);

  const { updateProductRef } = useDispatchEvent();

  React.useEffect(() => {
    (async function () {

      if (quoteId) {

        setIsGettingQuoteBundle(true);

        try {

          const _quoteBundle = await symphony.getQuote(quoteId, allowQuoteReRate, utmSource, utmMedium, utmCampaign);
          setQuoteBundle(_quoteBundle);
          updateProductRef(_quoteBundle.requestedQuote.productReferenceID);
        } catch (error) {
          throwError(error);
        }

        setIsGettingQuoteBundle(false);

      }

    }());
  }, [quoteId]);

  return {
    quoteBundle,
    isGettingQuoteBundle
  };

}

export type UseGetQuote = (
  quoteId?: string,
  allowQuoteReRate?: boolean,
  utmSource?: string,
  utmMedium?: string,
  utmCampaign?: string
) => {
  quoteBundle: QuoteBundle | undefined,
  isGettingQuoteBundle: boolean
}
