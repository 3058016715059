import { useAsyncEffect } from 'use-async-effect';
import { useSymphonyClient } from '@aventus/symphony-client/hooks';
import { useOpusClient } from '@aventus/opus-client/hooks';
import { isEmpty } from 'lodash';
import { VoucherInfo } from '@aventus/platform';

export function useVoucher(
  voucherActions: {
    setVoucherInfo: (arg: VoucherInfo | undefined) => void;
    setIsWorking: (arg: boolean) => void;
    setVoucherError: (arg: string | undefined) => void;
  },
  risk: any,
  tobesState: any,
  callBack: (value: boolean | null) => void,
  setRisk?: (value: any, riskPath: string) => void,
  setRiskHash?: (value: string) => void
): IUseVoucher {
  const symphony = useSymphonyClient(true);

  const opus = useOpusClient(true);

  const takeRiskAction = (
    riskActions: { value: any; riskPath: string }[] | undefined
  ) => {
    setTimeout(function () {
      if (riskActions && !isEmpty(riskActions) && setRisk) {
        riskActions.forEach(({ value, riskPath }) => {
          setRisk(value, riskPath);
        });
      }
    }, 1000);
  };

  const applyVoucher = async (voucherCode: string) => {
    voucherActions.setIsWorking(true);

    voucherActions.setVoucherError(undefined);
    try {
      const _apply = symphony
        ? await symphony?.applyVoucher(risk, voucherCode, tobesState)
        : await opus?.applyVoucher(risk, voucherCode, tobesState);

      if (_apply) {
        voucherActions.setVoucherInfo(_apply.voucher);
        callBack(null);

        if (_apply.tobesState && setRiskHash) {
          callBack(true);
          setRiskHash(_apply.tobesState);
          takeRiskAction(_apply.riskActions);
        }
      }
      voucherActions.setIsWorking(false);
    } catch (error) {
      if (error instanceof Error) {
        voucherActions.setVoucherError(error.message);
      }

      voucherActions.setIsWorking(false);
    }
  };

  const resetVoucher = (fireCallback: boolean = true) => {
    voucherActions.setVoucherInfo(undefined);
    voucherActions.setVoucherError(undefined);
    voucherActions.setIsWorking(false);
    fireCallback && callBack(null);
  };

  const removeVoucher = async () => {
    voucherActions.setIsWorking(true);
    voucherActions.setVoucherError(undefined);
    try {
      const _response = symphony
        ? await symphony?.removeVoucher(tobesState)
        : await opus?.removeVoucher(tobesState);

      if (_response.tobesState && setRiskHash) {
        setRiskHash(_response.tobesState);
      }

      takeRiskAction(_response.riskActions);
      resetVoucher();
      callBack(false);
    } catch (error) {
      if (error instanceof Error) {
        voucherActions.setVoucherError(error.message);
      }
      voucherActions.setIsWorking(false);
    }
  };

  useAsyncEffect(async () => {
    if (tobesState) {
      voucherActions.setIsWorking(true);
      try {
        const _voucher = symphony
          ? await symphony?.getVoucherInfo(tobesState)
          : await opus?.getVoucherInfo(tobesState);

        if (_voucher) {
          voucherActions.setVoucherInfo(_voucher);
          callBack(true);
        }
        voucherActions.setIsWorking(false);
      } catch (error) {
        if (error instanceof Error) {
          voucherActions.setVoucherError(error.message);
        }
        voucherActions.setIsWorking(false);
      }
    }
  }, []);

  return {
    tobes: {
      applyVoucher,
      removeVoucher,
      resetVoucher
    }
  };
}

export interface IUseVoucher {
  tobes: {
    removeVoucher: () => void;
    applyVoucher: (voucherCode: string) => void;
    resetVoucher: (fireCallback?: boolean) => void;
  };
}
