import { BladeLoader } from '@aventus/blade';
import { loadFatZebra } from '@aventus/fatzebra.js';
import React, { useEffect, useMemo } from 'react';
import css from './index.css';
import { IFatZebraConfigResponse } from '@aventus/platform';

export const FatZebraPayByCard: React.FC<IFatZebraPayByCard> = ({
  fzConfig,
  paymentIntent,
  onRecoverableError,
  onReady
}) => {
  const [initialized, setInitialzed] = React.useState<boolean>(false);

  const fatZebraPromise = useMemo(
    () =>
      loadFatZebra(null, {
        username: fzConfig.key,
        test: Boolean(fzConfig.isSandbox)
      }),
    [fzConfig]
  );

  // Initialize the FatZebra SDK on first render.
  // We store the SDK in a ref and update when we
  // have the access_token available.
  useEffect(() => {
    async function init() {
      if (!initialized) {
        fatZebraPromise
          .then(fatZebra => {
            if (fatZebra) {
              fatZebra.renderPaymentsPage({
                containerId: 'checkoutIframe',
                paymentIntent: paymentIntent,
                options: {
                  // Hpp display options
                  hideButton: true,
                  tokenizeOnly: true,
                  hideLogos: true,
                  enableSca: false,
                  css: fzConfig.cssUrl,
                  cssSignature: fzConfig.cssSignature
                },
              });

              setInitialzed(true);

              if (typeof onReady === 'function') {
                onReady(fatZebra);
              }
            }
          })
          .catch(() => {
            onRecoverableError(
              'fatzebraerror',
              'Unable to initialize checkout.'
            );
          });
      }
    }

    init();
  }, [
    initialized,
    fatZebraPromise,
    onRecoverableError,
    onReady,
    paymentIntent
  ]);

  return (
    <div id="checkoutIframe" className={css.checkout_wrapper}>
      {!initialized && <BladeLoader />}
    </div>
  );
};

interface IFatZebraPayByCard {
  fzConfig: IFatZebraConfigResponse;
  paymentIntent: IPaymentIntent;
  onRecoverableError: (id: string, message: string) => void;
  onReady?: (fatZebra: any) => void;
}
