import * as React from 'react';
import {
  Quote,
  PricingSet,
  MtaInformation,
  Policy,
  PaymentPlanType,
  PaymentMethod
} from '@aventus/platform';
import {
  InsuranceProductConfiguration,
  OrganisationConfiguration
} from '@aventus/configuration';
import { useTrack } from '@aventus/application-tracking';
import { ApplicationError } from '@aventus/errors';
import {
  usePaymentPlanWithProviders,
  UsePaymentPlanWithProviders
} from '../../hooks/use-payment-plan-with-providers';
import {
  useAdjustmentInformation,
  UseAdjustmentInformation
} from '../../hooks/use-adjustment-information';
import { useGetPolicyPaymentMethod } from '../../hooks/use-get-policy-payment-method';
import { usePurchasedPolicy } from '../../hooks/use-purchased-policy';
import { useError } from '@aventus/pockethooks';
import { payNow } from '../../services/pay-now';
import { payNowFatZebra } from '../../services/pay-now-fatzebra';
import { adjustNowFatZebra } from '../../services/adjust-now-fatzebra';
import { adjustNow } from '../../services/adjust-now';
import { Lifecycles } from '../../interface/lifecycles';
import { currencyPenceToPounds } from '@aventus/pocketknife';
import { useBladeInlineError } from '@aventus/blade/inline-error';
import BNPPayment from '@aventus/payment-provider-bnp/classes/bnp-payment';
import {
  BinderConfiguration,
  BinderPaymentProvider
} from '@aventus/mvmt-checkout/interface/binder';

export const useCheckout = (
  quote: Quote,
  quotePricing: PricingSet | undefined,
  defaultPaymentPlanType: PaymentPlanType | undefined,
  adjustmentInformation: MtaInformation | undefined,
  lifecycles: Lifecycles,
  productConfiguration: InsuranceProductConfiguration,
  organisationConfiguration: OrganisationConfiguration
): UseCheckout => {
  const { track } = useTrack();

  const { setError } = useBladeInlineError();

  // We want to keep a local copy of the quote pricing,
  // and use this as the source of pricing truth, instead of the
  // prop the Checkout component was called with.
  // This allows us to update the quote pricing in one place
  // when vouchers are applied, and a new discounted quote
  // pricing returned.

  const [quotePricingLocal, setQuotePricingLocal] = React.useState<
    PricingSet | undefined
  >(quotePricing);

  // If the user is checking out an adjustment, this module
  // defines an internal representation of the type of adjustment,
  // usually grouped by the type of action required;
  // payment, no fee, refund or update of finance.

  const { adjustmentType } = useAdjustmentInformation(adjustmentInformation);

  // Organise the pricing plans, which one is selected,
  // and handling the specific payment provider payment
  // collections.

  const {
    selectedPricingPlan,
    setSelectedPricingPlan,
    otherPricingPlan,
    providerPayments,
    payment,
    depositPayment,
    onProviderPaymentReady,
    canMakePayment
  } = usePaymentPlanWithProviders(
    quotePricingLocal,
    defaultPaymentPlanType,
    adjustmentInformation,
    adjustmentType,
    quote.paymentPlanReferenceID
  );

  // Quite self-explanatory, but this is a global
  // flag that will indicate when ANY checkout flow
  // is in execution or not.

  // An important distinction to keep in mind, this flag
  // will be switch off after the `onGetPurchasedPolicy`,
  // but before the `onFinish` lifecycle.
  // This is to allow for a success state to be displayed
  // to the user after a policy is purchased, and then
  // trigger off the next steps (usually redirecting to the
  // policy view) behind the scenes.

  const [isCheckingOut, setIsCheckingOut] = React.useState<boolean>(false);
  const [isCheckedOut, setIsCheckedOut] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (isCheckingOut === true) {
      track({
        event: 'aventus.checkout.start',
        quoteId: quote.id,
        productReference: productConfiguration.productReference,
        productCoverReference: productConfiguration.productCoverReference,
        policyGross: selectedPricingPlan?.totalPayable.gross.value
          ? currencyPenceToPounds(selectedPricingPlan?.totalPayable.gross.value)
          : undefined,
        quoteType: quote.quoteType
      });
    }
  }, [isCheckingOut]);

  // When a customer has payed for a quote, or amended
  // a policy, the final result is a policy object.
  // When we've got a policy object, we can execute the
  // onFinish lifecycle, which takes the customer away from
  // this checkout view.

  const { purchasedPolicy, setPurchasedPolicy } = usePurchasedPolicy(
    productConfiguration.checkout.success,
    quote.quoteType,
    selectedPricingPlan?.paymentPlan.type,
    adjustmentType,
    {
      support: organisationConfiguration.links.support,
      trustpilot: organisationConfiguration.links.trustpilot
    },
    lifecycles.onFinish
  );

  React.useEffect(() => {
    if (purchasedPolicy?.id) {
      let eventName = 'aventus.policy.bind';

      if (purchasedPolicy.policyType.toLowerCase() === 'renewal') {
        eventName = 'aventus.policy.renewal.bind';
      }

      if (quote.quoteType.toLocaleLowerCase() === 'mta') {
        eventName = 'aventus.policy.mta.bind';
      }

      track({
        event: eventName,
        partnerID: purchasedPolicy?.partnerID,
        partnerReference: purchasedPolicy?.partnerReference,
        policyId: purchasedPolicy.id,
        productReference: productConfiguration.productReference,
        productCoverReference: productConfiguration.productCoverReference,
        policyGross: selectedPricingPlan?.totalPayable.gross.value
          ? currencyPenceToPounds(selectedPricingPlan?.totalPayable.gross.value)
          : undefined,
        quoteType: quote.quoteType
      });
    }
  }, [purchasedPolicy]);

  // Because we're not using client hooks,
  // and the `payNow` and `adjustNow` functions are simply
  // vanilla async functions, we need to pipe through the
  // bridge for them to be able to throw errors within
  // the React tree boundary context

  const { throwError } = useError();

  // If we're dealing with an adjustment of policy
  // bought with a subscription payment plan, we'll need to fetch
  // the details of the saved card.

  const { policyPaymentMethod, isGettingPolicyPaymentMethod } =
    useGetPolicyPaymentMethod(
      lifecycles.onGetPolicyPaymentMethod,
      adjustmentInformation?.originalPolicy.id,
      adjustmentType
    );

  return {
    selectedPricingPlan,
    setSelectedPricingPlan,
    otherPricingPlan,
    providerPayments,
    onProviderPaymentReady,
    payment,
    depositPayment,
    canMakePayment,
    adjustmentType,

    isCheckingOut,
    setIsCheckingOut,
    isCheckedOut,

    quotePricing: quotePricingLocal,
    updateQuotePricing: setQuotePricingLocal,

    policyPaymentMethod,
    isGettingPolicyPaymentMethod,
    checkoutNow: async () => {
      if (!canMakePayment || !payment) {
        throwError(
          new ApplicationError(
            'Checkout is attempting to `payNow`, however the validation logic seems to be incorrect as the state is invalid, or no payment request definition was found.'
          )
        );
        return;
      }

      const providerPayment = providerPayments?.[payment];

      if (!providerPayment) {
        throwError(
          new ApplicationError(
            'The provider payment for the specified provider was not found in the providers store.'
          )
        );
        return;
      }

      switch (providerPayment.provider) {
        case 'FatZebra':
          // When buy now is clicked we simply need to fire the
          // checkout() function. This submits the form within the iframe
          // and sends back events to us which are already pre-registered.
          setIsCheckingOut(true);
          providerPayment.providerRequest.fatZebra.checkout();
          break;
        default:
          // This might be used for other providers that have an event based checkout
          // or if we need to add a step before launching payNow(). For now this is
          // blank.
          break;
      }
    },
    payNow: async (token?: string) => {
      if (!canMakePayment || !payment || !selectedPricingPlan) {
        throwError(
          new ApplicationError(
            'Checkout is attempting to `payNow`, however the validation logic seems to be incorrect as the state is invalid, or no payment request definition was found.'
          )
        );
        return;
      }

      const providerPayment = providerPayments?.[payment];

      if (!providerPayment) {
        throwError(
          new ApplicationError(
            'The provider payment for the specified provider was not found in the providers store.'
          )
        );
        return;
      }

      let policy: Policy | undefined;

      const binderPaymentProvider: BinderPaymentProvider = {
        payment: providerPayment,
        deposit: depositPayment ? providerPayments?.[depositPayment] : undefined
      };

      const configuration: BinderConfiguration = {
        quoteID: quote.id,
        quoteType: quote.quoteType,
        encryptedDiscount: quotePricingLocal?.discountState || null,
        encryptedCreditPlan: selectedPricingPlan.encryptedState || null,
        paymentPlanReferenceID:
          selectedPricingPlan.paymentPlan.paymentPlanReferenceID,
        paymentPlanType: selectedPricingPlan.paymentPlan.type,
        adjustmentType,
        adjustmentInformation
      };

      switch (providerPayment.provider) {
        case 'BNP':
          setIsCheckingOut(true);

          const bnpPayment = new BNPPayment(
            binderPaymentProvider,
            configuration,
            lifecycles,
            (error: any) => {
              track({
                event: 'aventus.checkout.fail',
                quoteId: quote.id,
                productReference: productConfiguration.productReference,
                productCoverReference:
                  productConfiguration.productCoverReference,
                policyGross: selectedPricingPlan?.totalPayable.gross.value
                  ? currencyPenceToPounds(
                    selectedPricingPlan?.totalPayable.gross.value
                  )
                  : undefined
              });
              throwError(error);
              setIsCheckingOut(false);
            },
            (id, message) => {
              setError(id, message);
              setIsCheckingOut(false);
            }
          );

          policy = await bnpPayment.pay();

          setIsCheckingOut(false);
          lifecycles.onClearPaymentToken();

          if (policy !== undefined) {
            setIsCheckedOut(true);
            setPurchasedPolicy(policy);
          }
          break;
        case 'FatZebra':
          if (token) {
            payNowFatZebra(
              token,
              configuration,
              lifecycles,
              setIsCheckingOut,
              policy => {
                lifecycles.onClearPaymentToken();
                if (policy !== undefined) {
                  setIsCheckedOut(true);
                  setPurchasedPolicy(policy);
                }
              },
              setError,
              productConfiguration
            );
          } else {
            setIsCheckingOut(false);
            setError(
              'fatzebraerror',
              'Our apologies, there was an error confirming your card with our payment provider, please try again or contact customer support.'
            );
          }
          break;
        default:
          setIsCheckingOut(true);
          policy = await payNow(
            providerPayment,
            binderPaymentProvider.deposit,
            configuration,
            lifecycles,
            error => {
              track({
                event: 'aventus.checkout.fail',
                quoteId: quote.id,
                productReference: productConfiguration.productReference,
                productCoverReference:
                  productConfiguration.productCoverReference,
                policyGross: selectedPricingPlan?.totalPayable.gross.value
                  ? currencyPenceToPounds(
                    selectedPricingPlan?.totalPayable.gross.value
                  )
                  : undefined
              });
              throwError(error);
            },
            setError,
            undefined,
            productConfiguration.checkout.error
          );

          setIsCheckingOut(false);
          lifecycles.onClearPaymentToken();
          if (policy !== undefined) {
            setIsCheckedOut(true);
            setPurchasedPolicy(policy);
          }
      }
    },

    adjustNow: async (token?: string) => {
      if (!canMakePayment || !adjustmentInformation) {
        throw new ApplicationError(
          'Checkout is attempting to `adjustNow`, however the validation logic seems to be incorrect as the state is invalid, or no payment request definition was found.'
        );
      }

      let policy: Policy | undefined;

      const binderPaymentProvider: BinderPaymentProvider = {
        payment: payment ? providerPayments?.[payment] : undefined,
        deposit: depositPayment ? providerPayments?.[depositPayment] : undefined
      };

      const configuration: BinderConfiguration = {
        quoteID: quote.id,
        quoteType: quote.quoteType,
        encryptedDiscount: quotePricingLocal?.discountState || null,
        encryptedCreditPlan: null,
        paymentPlanReferenceID:
          adjustmentInformation.paymentPlan.paymentPlanReferenceID,
        paymentPlanType: adjustmentInformation.paymentPlan.type,
        adjustmentType,
        adjustmentInformation
      };

      switch (adjustmentInformation.paymentPlan.paymentProvider) {
        case 'BNP':
          const bnpPayment = new BNPPayment(
            binderPaymentProvider,
            configuration,
            lifecycles,
            (error: any) => {
              track({
                event: 'aventus.checkout.fail',
                quoteId: quote.id,
                productReference: productConfiguration.productReference,
                productCoverReference:
                  productConfiguration.productCoverReference,
                policyGross: selectedPricingPlan?.totalPayable.gross.value
                  ? currencyPenceToPounds(
                    selectedPricingPlan?.totalPayable.gross.value
                  )
                  : undefined
              });
              throwError(error);
              setIsCheckingOut(false);
            },
            (id, message) => {
              setError(id, message);
              setIsCheckingOut(false);
            }
          );

          setIsCheckingOut(true);

          // This needs more work.
          // Loading states are not accounted for.
          policy = await bnpPayment.adjust();

          setIsCheckingOut(false);

          if (policy !== undefined) {
            setIsCheckedOut(true);
            setPurchasedPolicy(policy);
          }

          break;

        case 'FatZebra':
          adjustNowFatZebra(
            configuration,
            lifecycles,
            setIsCheckingOut,
            policy => {
              if (policy !== undefined) {
                setIsCheckedOut(true);
                setPurchasedPolicy(policy);
              }
            },
            setError,
            token
          );
          break;
        default:
          setIsCheckingOut(true);

          policy = await adjustNow(
            binderPaymentProvider.payment,
            configuration,
            lifecycles,
            error => {
              track({
                event: 'aventus.checkout.fail',
                quoteId: quote.id,
                productReference: productConfiguration.productReference,
                productCoverReference:
                  productConfiguration.productCoverReference,
                policyGross: selectedPricingPlan?.totalPayable.gross.value
                  ? currencyPenceToPounds(
                    selectedPricingPlan?.totalPayable.gross.value
                  )
                  : undefined
              });
              throwError(error);
            },
            setError
          );

          setIsCheckingOut(false);
          if (policy !== undefined) {
            setIsCheckedOut(true);
            setPurchasedPolicy(policy);
          }
      }
    }
  };
};

export interface UseCheckout
  extends UsePaymentPlanWithProviders,
  UseAdjustmentInformation {
  checkoutNow: () => void;
  payNow: (token?: string) => void;
  adjustNow: (token?: string) => void;
  isCheckingOut: boolean;
  setIsCheckingOut: (isCheckingOut: boolean) => void;
  isCheckedOut: boolean;
  quotePricing: PricingSet | undefined;
  updateQuotePricing: (quotePricing: PricingSet) => void;
  policyPaymentMethod?: PaymentMethod;
  isGettingPolicyPaymentMethod: boolean;
}
