export enum EventType {
  ProductReferenceUpdated = 'ProductReferenceUpdated',
  ChildComponentUpdated = 'ChildComponentUpdated',
  PackageIsLoading = 'PackageIsLoading',
  Unknown = 'Unknown'
}

export const updateProductRef = (referenceId: Nullable<string>) => {
  dispatchEvent(EventType.ProductReferenceUpdated, { ref: referenceId });
};

export const childComponentUpdated = (parentRef: Nullable<string>) => {
  dispatchEvent(EventType.ChildComponentUpdated, { parentRef: parentRef });
};

export const packageIsLoading = (isLoading: boolean) => {
  dispatchEvent(EventType.PackageIsLoading, { isLoading: isLoading });
};

const dispatchEvent = (type: EventType, value: any) => {
  window.dispatchEvent(
    new CustomEvent(type, {
      detail: value
    })
  );
}; 