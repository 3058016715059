import { PolicyAddon, PolicyBundle } from '@aventus/platform';
import { IBladeActionItem } from '@aventus/blade';
import { InsuranceProductConfiguration } from '@aventus/configuration';
import { RouteComponentProps } from 'react-router';
import { GenericMenuConfigurationItemAlert } from '@aventus/configuration/product';

export const getSummaryActions = (
  route: RouteComponentProps,
  policyBundle: PolicyBundle,
  productConfiguration: InsuranceProductConfiguration,
  renewalSupport: boolean,
  isHybrid: boolean,
  policyAddons?: PolicyAddon[],
  setRender?: (arg: boolean) => void
): IBladeActionItem[] => {
  const { policy, actions: allowedActions, creditAgreement } = policyBundle;

  const isCancelled = policy.policyStatus === 'Cancelled';

  var actions: IBladeActionItem[] = [];

  if (
    renewalSupport &&
    productConfiguration?.policy.renewal &&
    policyBundle.policy.policyStatus &&
    !isCancelled
  ) {
    actions.push({
      image: productConfiguration?.policy.renewal.image,
      icon: productConfiguration?.policy.renewal.icon,
      label: productConfiguration?.policy.renewal.label,
      action: () => route.history.push(`/policy/${policy.id}/renew`)
    });
  }

  actions.push({
    image: productConfiguration?.policy.summaryOfCover.image,
    icon: productConfiguration?.policy.summaryOfCover.icon,
    label: productConfiguration?.policy.summaryOfCover.label,
    action: () => {
      route.history.push(`/policy/${policy.id}/summary-of-cover`);
    }
  });

  const canAdjustPolicy =
    allowedActions &&
    allowedActions.find(action => action.referenceID === 'avt_wfs_policymta');

  if (productConfiguration?.policy.documents) {
    if (
      policy.documents[0].policyDocumentStatus !== 'Ready' &&
      !productConfiguration.policy.summary.redirectToDocuments
    ) {
      actions.push({
        image: productConfiguration?.policy.documents.image,
        icon: productConfiguration?.policy.documents.icon,
        label: productConfiguration?.policy.documents.label,

        action: async () => {
          const docsPending =
            policy.documents &&
            policy.documents.some(
              document => document.policyDocumentStatus !== 'Ready'
            );

          if (docsPending) {
            setRender && setRender(true);
          }

          if (!docsPending) {
            window.open(`${policy.documents[0].documentUrl}`, '_blank');
          }
        }
      });
    } else if (
      policy.documents.length === 1 &&
      !productConfiguration.policy.summary.redirectToDocuments
    ) {
      actions.push({
        image: productConfiguration?.policy.documents.image,
        icon: productConfiguration?.policy.documents.icon,
        label: productConfiguration?.policy.documents.label,
        action: () => {
          window.open(`${policy.documents[0].documentUrl}`, '_blank');
        }
      });
    } else {
      actions.push({
        image: productConfiguration?.policy.documents.image,
        icon: productConfiguration?.policy.documents.icon,
        label: productConfiguration?.policy.documents.label,
        action: () => route.history.push(`/policy/${policy.id}/documents`)
      });
    }
  }

  if (!isCancelled && productConfiguration?.policy.adjustCoverPeriod && !(productConfiguration?.policy.adjustCoverPeriod.hideWhenPolicyIsLapsed && policyBundle.policy.policyStatus === 'Lapsed')) {
    if (canAdjustPolicy && canAdjustPolicy.allowed) {

      actions.push({
        image: productConfiguration?.policy.adjustCoverPeriod.image,
        icon: productConfiguration?.policy.adjustCoverPeriod.icon,
        label: productConfiguration?.policy.adjustCoverPeriod.label,
        action: () => {
          route.history.push(
            `/quote/adjust?policyId=${policy.id}&questionSetReferenceId=${productConfiguration?.policy.adjustCoverPeriod.questionSetReferenceId}`
          )
        }

      });
    } else {
      var alert = productConfiguration?.policy.adjustCoverPeriod.alerts?.find(a => canAdjustPolicy && a.errorCode === canAdjustPolicy.errorCode) ??
        productConfiguration?.policy.adjustCoverPeriod.alerts?.find(a => a.errorCode == null);

      let al: GenericMenuConfigurationItemAlert[] = alert ? [alert] : [];

      actions.push({
        image: productConfiguration?.policy.adjustCoverPeriod.image,
        icon: productConfiguration?.policy.adjustCoverPeriod.icon,
        label: productConfiguration?.policy.adjustCoverPeriod.label,
        action: () => { },
        alerts: al,
        disabled: true
      });
    }
  }

  if (!isCancelled && productConfiguration?.policy.adjust && !(productConfiguration?.policy.adjust.hideWhenPolicyIsLapsed && policyBundle.policy.policyStatus === 'Lapsed')) {
    if (canAdjustPolicy && canAdjustPolicy.allowed) {

      actions.push({
        image: productConfiguration?.policy.adjust.image,
        icon: productConfiguration?.policy.adjust.icon,
        label: productConfiguration?.policy.adjust.label,
        action: () => {
          if (isHybrid) {
            route.history.push(`/products/${policy.productID}/quote/MTA/avt_qs_core/1?policyId=${policy.id}`)
          } else {
            route.history.push(
              `/mta/${policy.productID}/${policy.lineOfBusiness}/${policy.coverType}/${policy.id}`
            )
          }
        }

      });
    } else {
      var alert = productConfiguration?.policy.adjust.alerts?.find(a => canAdjustPolicy && a.errorCode === canAdjustPolicy.errorCode) ??
        productConfiguration?.policy.adjust.alerts?.find(a => a.errorCode == null);

      let al: GenericMenuConfigurationItemAlert[] = alert ? [alert] : [];

      actions.push({
        image: productConfiguration?.policy.adjust.image,
        icon: productConfiguration?.policy.adjust.icon,
        label: productConfiguration?.policy.adjust.label,
        action: () => { },
        alerts: al,
        disabled: true
      });
    }
  }

  if (creditAgreement?.agreementHistory) {
    const showCardUpdate: boolean =
      creditAgreement?.agreementHistory.agreementHistoryType !==
      'Cancellation' &&
      creditAgreement?.paymentPlanType === 'AnnualMonthlySubscription';
    if (showCardUpdate) {
      actions.push({
        image: undefined,
        icon: 'faCreditCard',
        label: 'Update your payment details',
        action: () =>
          route.history.push(`/policy/${policy.id}/update-card-details`)
      });
    }
  }

  actions.push({
    image: productConfiguration?.policy.claim.image,
    icon: productConfiguration?.policy.claim.icon,
    label: productConfiguration?.policy.claim.label,
    action: () => route.history.push(`/policy/${policy.id}/claim/main`)
  });

  if (policyAddons && policyAddons.length > 0) {
    policyAddons?.map(pa => {
      var addonConfigItem = productConfiguration?.policy.addons.filter(
        a => a.label == pa.name
      )[0];
      if (addonConfigItem) {
        actions.push({
          label: pa.name,
          icon: addonConfigItem.icon,
          image: addonConfigItem.image,
          action: () =>
            route.history.push(
              `/policy/${policy.id}/claim/${pa.scheme.referenceCode}`
            )
        });
      }
    });
  }

  if (!isCancelled && productConfiguration?.policy.cancel) {
    actions.push({
      image: productConfiguration?.policy.cancel.image,
      icon: productConfiguration?.policy.cancel.icon,
      label: productConfiguration?.policy.cancel.label,
      action: () => route.history.push(`/policy/${policy.id}/cancel`)
    });
  }

  return actions;
};
